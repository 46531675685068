.slider-items {
    max-height: 300px;
    /* max-width: 400px; */
    height: 300px;
    /* min-width: 400px; */
    /* margin: 10px; */
    padding: 20px;
    border-radius: 70px;
}

.img-content {
    height: 80%;
    width: 100%;
    /* display: flex; */
}

.img-content img {
    object-fit: fill;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px 10px 0 0;
}

.text-content {
    background-color: rgb(255, 255, 255);
    height: 20%;
    border-radius: 0 0 10px 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.text-content p {
    font-weight: 500;
    margin: 0;
}

.back-top {
    display: flex;
    background-color: #ff7d27;;
    width: 40px;
    height: 40px;
    padding: 8px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    justify-content: center;
    align-items: center;
}

.contact-us{
    color: white;
    padding: 20px 50px;
    font-weight: 500;
    /* background-color: rgb(207, 207, 207); */
    /* height: 100%; */
}

/* .footer-col {
    width: 100%;
    padding: 0 15px;
    margin: 10px 0;
} */

.footer-icon-container{
    display: flex;
    max-width: 20px;
    max-height: 20px;
    min-width: 20px;
    min-height: 20px;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.footer-cu-spacer{
    width: 5px;
    padding: 0;
}

.no-wrap{
    flex-wrap: nowrap;
}

p {
    font-size: 15px;
    line-height: 25px;
  }
  
  section {
    padding: 25px 0 35px;
    background: #fff;
  }
  
  body {
    position: relative;
    font-size: 14px;
  }
  
  a {
    color: #3CC;
  }
  
  a:hover {
    color: #FF0;
  }
  
  .fixed-side-navbar {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 99999;
    margin-top: -100px;
    text-align: right;
    padding: 30px 0;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  
  .fixed-side-navbar:hover {
    background: transparent;
  }
  
  .fixed-side-navbar:hover .nav>li>a>span {
    color: rgba(0, 0, 0, 0.5);
    display: block;
    background-color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    padding: 2px 12px;
    border-radius: 15px;
  }
  
  .fixed-side-navbar .nav>li a.active {
    background-color: transparent;
    color: black;
  }
  
  .fixed-side-navbar .nav>li a.active:after {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  
  .fixed-side-navbar .nav>li a.active:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 17px;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 10px;
  }
  
  .nav>li {
    position: relative;
    display: block;
  }
  
  .fixed-side-navbar .nav>li a {
    color: #fff;
    min-height: 32px;
    background: transparent;
    padding: 5px 45px 5px 25px;
    border-right: none;
  }
  
  .fixed-side-navbar .nav>li a span {
    display: none;
    -webkit-transition: all .2s;
    transition: all .2s;
  }
  
  .fixed-side-navbar .nav>li a:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -5px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: rgba(250, 250, 250, 0.75);
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  
  .fixed-side-navbar .nav>li a:hover {
    background-color: transparent;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-color: black;
    border-right: 0;
  }
  
  .fixed-side-navbar .nav>li a:hover>span {
    color: black;
    display: block;
  }
  
  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
  
  .primary-button a {
    display: inline-block;
    background-color: #ff7d27;
    padding: 15px 24px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 11px;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 0.5px;
  }
  
  .parallax-content {
    width: 100%;
    min-height: 70vh;
    background-size: cover;
  }
  
  .baner-content,
  .service-content,
  .projects-content,
  .contact-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
  }
  
  .baner-content {
    text-align: center;
    background-image: url(../../assets/img/1st-section.webp);
  }
  
  .baner-content h1 {
    margin-top: 0px;
    font-size: 128px;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 0px;
  }
  
  .baner-content em {
    color: #ff7d27;
    font-weight: 600;
    font-style: normal;
  }
  
  .baner-content span {
    display: inline-block;
    margin-top: -20px;
    font-weight: 300;
    font-size: 48px;
    color: #fff;
  }
  
  .baner-content .primary-button {
    margin-top: 15px;
  }
  
  .service-content {
    background-image: url(../../assets/img/2nd-section.webp);
  }
  
  .service-content .left-text h4 {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
  }
  
  h4,
  .h4 {
    font-size: 18px;
  }
  
  .service-content .left-text .line-dec {
    width: 45px;
    height: 3px;
    background-color: #ff7d27;
    margin: 20px 0px 20px 0px;
  }
  
  .service-content .left-text p {
    color: #fff;
  }
  
  .service-content .left-text ul {
    padding: 0;
    margin-top: 30px;
    list-style: none;
  }
  
  .service-content .left-text ul li {
    margin: 15px 0px;
    font-weight: 600;
    color: #fff;
  }
  
  .service-content .left-text .primary-button {
    margin: 30px 0px;
  }
  
  .service-content .service-item {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 25px 30px;
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
  }
  
  .service-content .service-item .line-dec {
    width: 45px;
    height: 3px;
    background-color: #ff7d27;
    margin: 20px auto 15px auto;
  }
  
  .projects-content {
    background-image: url(../../assets/img/3rd-section.webp);
  }
  
  .projects-content .item img {
    width: 100%;
  }
  
  .projects-content .item {
    margin: 15px;
  }
  
  .projects-content .item .text-content {
    background-color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  .projects-content .item .text-content h4 {
    margin-top: 0px;
    font-size: 19px;
    font-weight: 600;
  }
  
  .projects-content .item .text-content span {
    font-style: normal;
    font-size: 17px;
    font-weight: 700;
    color: #ff7d27;
  }
  
  .owl-pagination {
    margin-top: 40px;
    opacity: 1;
    display: inline-block;
  }
  
  .contact-content {
    background-image: url(../../assets/img/5th-sections.webp);
  }
  
  .contact-form {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px 25px;
    margin-bottom: 30px;
  }
  
  #contact input {
    border-radius: 3px;
    padding-left: 15px;
    font-size: 13px;
    color: #232323;
    background-color: rgba(250, 250, 250, 1);
    outline: none;
    border: none;
    box-shadow: none;
    line-height: 50px;
    height: 50px;
    width: 100%;
    margin-bottom: 30px;
  }
  
  .map {
    margin-bottom: 30px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px 20px;
  }
  
  footer {
    text-align: center;
    background-color: #282b2f;
    padding: 20px 0px;
  }
  
  footer p {
    font-size: 12px;
    color: #fff;
    margin-top: 15px;
    color: #fff;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
  }
  
  @media (max-width: 767px) {
  
    .baner-content h1 {
      font-size: 64px;
    }
  
    .baner-content span {
      font-size: 24px;
    }
  
    .baner-content .primary-button {
      margin-top: 15px;
    }
  
    .service-content {
      padding-top: 5vh;
      text-align: center;
    }
  
    .projects-content {
      text-align: center;
      min-height: 50vh;
    }
  
    .service-content .left-text .line-dec {
      width: 45px;
      height: 3px;
      background-color: #ff7d27;
      margin: 20px auto 20px auto;
    }
  
    .service-content .left-text ul {
      text-align: left;
    }
  
    .service-content .service-item {
      background-color: rgba(0, 0, 0, 0.5);
      padding: 25px 30px;
      text-align: center;
      color: #fff;
      margin-bottom: 30px;
    }
  
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1170px;
    }
  }
  
  body:after {
    content: url(../../assets/img/close.png) url(../../assets/img/loading.gif) url(../../assets/img/prev.png) url(../../assets/img/next.png);
    display: none;
  }
  
  body.lb-disable-scrolling {
    overflow: hidden;
  }

